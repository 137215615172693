import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PropertiesAttributesStoreEffects } from './effects';
import { propertiesAttributesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('propertiesAttributes', propertiesAttributesReducer),
    EffectsModule.forFeature([PropertiesAttributesStoreEffects]),
  ],
})
export class PropertiesAttributesStoreModule {}
