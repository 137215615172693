import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class PropertiesAttributesService {
  constructor(private http: HttpClient) {}

  load(propertiesIds: number[]) {
    return this.http.get(
      `properties/attributes?${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }
}
