import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Property } from '../../models';

export const featureAdapter: EntityAdapter<Property> = createEntityAdapter<
  Property
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<Property> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
