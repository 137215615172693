import { createAction, props } from '@ngrx/store';

import { Property } from '../../models';

export const loadRequest = createAction(
  '[Properties Attributes] Load Request',
  props<{
    propertiesIds: number[];
  }>(),
);

export const loadSuccess = createAction(
  '[Properties Attributes] Load Success',
  props<{ items: Property[] }>(),
);

export const loadFailure = createAction(
  '[Properties Attributes] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Properties Attributes] Reset State');
